import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import SectionHeading from "components/_ui/SectionHeading";

const BenefitsContainer = styled.div`
  & + * {
    margin-top: 8rem;
  }

  &:last-of-type {
    margin-bottom: 8rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 14.4rem;
    }  

    &:last-of-type {
      margin-bottom: 14.4rem;
    }
  }
`;

const BenefitsContent = styled.div`
  padding: 2.4rem;
  background-color: ${colors.grey100};
  margin-top: 4.8rem;
  
  ul {
    columns: 30rem 3;
  }
  
  li {
    padding: 1.2rem;
    font-size: 1.6rem;
    text-align: center;
  }
  
  @media (min-width: ${dimensions.tabletPortraitUp}px) {
    li {
      text-align: left;
    }
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 6.4rem 11.2rem;
    margin-top: 6.4rem;
    
    li {
      font-size: 2rem;
    }
  }
`;

class Benefits extends Component {
  render() {
    return (
      <BenefitsContainer>
        <Inner>
          <SectionHeading>{this.props.heading}</SectionHeading>

          <BenefitsContent>
            <ul>
              {this.props.benefits.map((benefit, i) => (
                <li key={i}>
                  {benefit.text}
                </li>
              ))}
            </ul>
          </BenefitsContent>
        </Inner>
      </BenefitsContainer>
    );
  }
}

export default Benefits;
