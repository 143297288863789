import React from 'react';
import { graphql } from 'gatsby';
/*import styled from '@emotion/styled';*/
/*import colors from 'styles/colors';*/
/*import dimensions from 'styles/dimensions';*/
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import AboutHeader from 'components/_page/about/Header';
import SliceZone from "../components/SliceZone";
import Benefits from "components/_page/about/Benefits";
import Positions from "components/_page/about/Positions";
import Related from 'components/Related';

const RenderBody = ({ careers, culture, leadership }) => (
  <>
    <AboutHeader
      title='Careers'
      heading={careers.introduction_heading}
      content={careers.introduction_content}
      parallax={[-80, 100]}
    />

    {(careers.body && careers.body.length > 0) && (
      <SliceZone
        body={careers.body}
      />
    )}

    {(careers.benefits && careers.benefits.length > 0) && (
      <Benefits
        heading={careers.benefits_heading}
        benefits={careers.benefits}
      />
    )}

    {(careers.careers && careers.careers.length > 0) && (
      <Positions
        heading={careers.careers_heading}
        positions={careers.careers}
      />
    )}

    <Related
      heading='Learn more about us'
      type='trapdoor'
      cards={[
        {
          destination: '/culture',
          image: culture.feature_image,
          name: 'Culture',
          description: culture.short_description,
        },
        {
          destination: '/leadership',
          image: leadership.feature_image,
          name: 'Leadership',
          description: leadership.short_description,
        },
      ]}
    />
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const careers = data.prismic.allCareerss.edges[0].node;
  const culture = data.prismic.allCultures.edges[0].node;
  const leadership = data.prismic.allLeaderships.edges[0].node;

  if (!careers) return null;

  return (
    <Layout>
      <SEO
        title={careers.social_title || 'Careers'}
        description={careers.social_description ? careers.social_description : null}
        image={careers.social_image ? careers.social_image.url : null}
      />

      <RenderBody careers={careers} culture={culture} leadership={leadership} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allCareerss {
        edges {
          node {
            introduction_sections {
              heading
              content
            }
            introduction_heading
            introduction_content
            benefits_heading
            benefits {
              text  
            }
            careers_heading
            careers {
              position
              location
              external_url
            }
            body {
              ... on PRISMIC_CareersBodyText {
                type
                primary {
                  content
                }
              }
              ... on PRISMIC_CareersBodyCustom_html {
                type
                primary {
                  content
                }
              }
              ... on PRISMIC_CareersBodyContent_block {
                type
                primary {
                  image
                  content
                }
              }
            }
            social_title
            social_description
            social_image
          }
        }
      }
      allCultures {
        edges {
          node {
            feature_image
            short_description
          }
        }
      }
      allLeaderships {
        edges {
          node {
            feature_image
            short_description
          }
        }
      }
    }
  }
`;
